// SASS Variables for H&Co. Gotham Webfont at NYU

$fallback           : Arial, sans-serif;

// Gotham (18px +) //
$gotham-thin        : 100 normal 1em 'Gotham 1r', 'Gotham A', 'Gotham B', $fallback;
$gotham-xlight      : 200 normal 1em 'Gotham 2r', 'Gotham A', 'Gotham B', $fallback;
$gotham-light       : 300 normal 1em 'Gotham 3r', 'Gotham A', 'Gotham B', $fallback;
$gotham-book        : 400 normal 1em 'Gotham 4r', 'Gotham A', 'Gotham B', $fallback;
$gotham-medium      : 500 normal 1em 'Gotham 5r', 'Gotham A', 'Gotham B', $fallback;
$gotham-bold        : 700 normal 1em 'Gotham 7r', 'Gotham A', 'Gotham B', $fallback;
$gotham-black       : 800 normal 1em 'Gotham 8r', 'Gotham A', 'Gotham B', $fallback;
$gotham-ultra       : 900 normal 1em 'Gotham 9r', 'Gotham A', 'Gotham B', $fallback;

// Gotham (18px +) Italics//
$gotham-thin-i      : 100 italic 1em 'Gotham 1i', 'Gotham A', 'Gotham B', $fallback;
$gotham-xlight-i    : 200 italic 1em 'Gotham 2i', 'Gotham A', 'Gotham B', $fallback;
$gotham-light-i     : 300 italic 1em 'Gotham 3i', 'Gotham A', 'Gotham B', $fallback;
$gotham-book-i      : 400 italic 1em 'Gotham 4i', 'Gotham A', 'Gotham B', $fallback;
$gotham-medium-i    : 500 italic 1em 'Gotham 5i', 'Gotham A', 'Gotham B', $fallback;
$gotham-bold-i      : 700 italic 1em 'Gotham 7i', 'Gotham A', 'Gotham B', $fallback;
$gotham-black-i     : 800 italic 1em 'Gotham 8i', 'Gotham A', 'Gotham B', $fallback;
$gotham-ultra-i     : 900 italic 1em 'Gotham 9i', 'Gotham A', 'Gotham B', $fallback;

// Gotham ScreenSmart® (9-18px) //
$gothamss-xlight    : 200 normal 1em 'Gotham SSm 2r', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-light     : 300 normal 1em 'Gotham SSm 3r', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-book      : 400 normal 1em 'Gotham SSm 4r', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-medium    : 500 normal 1em 'Gotham SSm 5r', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-bold      : 700 normal 1em 'Gotham SSm 7r', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-black     : 800 normal 1em 'Gotham SSm 8r', 'Gotham SSm A', 'Gotham SSm B', $fallback;

// Gotham ScreenSmart® (9-18px) Italics //
$gothamss-xlight-i  : 200 italic 1em 'Gotham SSm 2i', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-light-i   : 300 italic 1em 'Gotham SSm 3i', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-book-i    : 400 italic 1em 'Gotham SSm 4i', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-medium-i  : 500 italic 1em 'Gotham SSm 5i', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-bold-i    : 700 italic 1em 'Gotham SSm 7i', 'Gotham SSm A', 'Gotham SSm B', $fallback;
$gothamss-black-i   : 800 italic 1em 'Gotham SSm 8i', 'Gotham SSm A', 'Gotham SSm B', $fallback;

// Gotham Narrow (18px +) //
$gothamnar-light    : 300 normal 1em 'Gotham Narrow 3r', 'Gotham Narrow A', 'Gotham Narrow B', $fallback;
$gothamnar-book     : 400 normal 1em 'Gotham Narrow 4r', 'Gotham Narrow A', 'Gotham Narrow B', $fallback;
$gothamnar-medium   : 500 normal 1em 'Gotham Narrow 5r', 'Gotham Narrow A', 'Gotham Narrow B', $fallback;
$gothamnar-bold     : 700 normal 1em 'Gotham Narrow 7r', 'Gotham Narrow A', 'Gotham Narrow B', $fallback;

// Gotham Narrow (18px +) Italics //
$gothamnar-light-i  : 300 italic 1em 'Gotham Narrow 3i', 'Gotham Narrow A', 'Gotham Narrow B', $fallback;
$gothamnar-book-i   : 400 italic 1em 'Gotham Narrow 4i', 'Gotham Narrow A', 'Gotham Narrow B', $fallback;
$gothamnar-medium-i : 500 italic 1em 'Gotham Narrow 5i', 'Gotham Narrow A', 'Gotham Narrow B', $fallback;
$gothamnar-bold-i   : 700 italic 1em 'Gotham Narrow 7i', 'Gotham Narrow A', 'Gotham Narrow B', $fallback;

// Gotham Extra Narrow (18px +) //
$gothamxnar-light   : 300 normal 1em 'Gotham XNarrow A', 'Gotham XNarrow B', $fallback;
$gothamxnar-book    : 400 normal 1em 'Gotham XNarrow A', 'Gotham XNarrow B', $fallback;
$gothamxnar-medium  : 500 normal 1em 'Gotham XNarrow A', 'Gotham XNarrow B', $fallback;
$gothamxnar-bold    : 700 normal 1em 'Gotham XNarrow A', 'Gotham XNarrow B', $fallback;

// Gotham Condensed (18px +) //
$gothamcon-book     : 400 normal 1em 'Gotham Cond A', 'Gotham Cond B', $fallback;
$gothamcon-medium   : 500 normal 1em 'Gotham Cond A', 'Gotham Cond B', $fallback;

// Gotham Condensed (18px +) Italics //
$gothamcon-book-i   : 400 italic 1em 'Gotham Cond A', 'Gotham Cond B', $fallback;
$gothamcon-medium-i : 500 italic 1em 'Gotham Cond A', 'Gotham Cond B', $fallback;

// Gotham Condensed ScreenSmart® (9-18px) //
$gothamconss-xlight : 200 normal 1em 'Gotham Cond SSm A', 'Gotham Cond SSm B', $fallback;
$gothamconss-light  : 300 normal 1em 'Gotham Cond SSm A', 'Gotham Cond SSm B', $fallback;
$gothamconss-book   : 400 normal 1em 'Gotham Cond SSm A', 'Gotham Cond SSm B', $fallback;
$gothamconss-medium : 500 normal 1em 'Gotham Cond SSm A', 'Gotham Cond SSm B', $fallback;
$gothamconss-bold   : 700 normal 1em 'Gotham Cond SSm A', 'Gotham Cond SSm B', $fallback;
