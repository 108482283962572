@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
/* HTML5 display-role reset for older browsers */
#GN-sub-nav div,
#GN-sub-nav span,
#GN-sub-nav h1,
#GN-sub-nav h2,
#GN-sub-nav h3,
#GN-sub-nav h4,
#GN-sub-nav h5,
#GN-sub-nav h6,
#GN-sub-nav p,
#GN-sub-nav blockquote,
#GN-sub-nav a,
#GN-sub-nav abbr,
#GN-sub-nav acronym,
#GN-sub-nav address,
#GN-sub-nav del,
#GN-sub-nav dfn,
#GN-sub-nav em,
#GN-sub-nav img,
#GN-sub-nav ins, q,
#GN-sub-nav strike,
#GN-sub-nav strong,
#GN-sub-nav sub,
#GN-sub-nav sup,
#GN-sub-nav b,
#GN-sub-nav i,
#GN-sub-nav dl,
#GN-sub-nav dt,
#GN-sub-nav dd,
#GN-sub-nav ol,
#GN-sub-nav ul,
#GN-sub-nav li,
#GN-sub-nav fieldset,
#GN-sub-nav form,
#GN-sub-nav label,
#GN-sub-nav legend,
#GN-sub-nav table,
#GN-sub-nav caption,
#GN-sub-nav tbody,
#GN-sub-nav tfoot,
#GN-sub-nav thead,
#GN-sub-nav tr,
#GN-sub-nav th,
#GN-sub-nav td,
#GN-sub-nav article,
#GN-sub-nav aside,
#GN-sub-nav details,
#GN-sub-nav embed,
#GN-sub-nav figure,
#GN-sub-nav figcaption,
#GN-sub-nav footer,
#GN-sub-nav header,
#GN-sub-nav menu,
#GN-sub-nav nav,
#GN-sub-nav output,
#GN-sub-nav section,
nav#GN-sub-nav nav {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  line-height: 1;
  vertical-align: baseline; }

#GN-sub-nav article,
#GN-sub-nav aside,
#GN-sub-nav figcaption,
#GN-sub-nav figure,
#GN-sub-nav footer,
#GN-sub-nav header,
#GN-sub-nav nav,
#GN-sub-nav section,
nav#GN-sub-nav nav {
  display: block; }

#GN-sub-nav ol,
#GN-sub-nav ul {
  list-style: none; }

#GN-sub-nav table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
body {
  margin: 0;
  padding: 0; }

*[id^='GN-'] *:focus, *[class^='GN-'] *:focus {
  outline: none;
  -webkit-box-shadow: inset 0 0 0 3px #3dbbdb;
  box-shadow: inset 0 0 0 3px #3dbbdb; }

.GN-full-width #GN-banner {
  width: 100% !important; }

.GN-full-width #GN-logo a {
  display: flex;
  height: 60px;
  position: relative;
  line-height: 60px;
  padding-left: 15px;
  padding-right: 15px; }

.GN-no-touch #GN-logo:hover,
.GN-no-touch #GN-toggle-local-nav:hover,
.GN-no-touch #GN-toggle-global-nav:hover,
.GN-no-touch a.GN-covid-btn:hover {
  background-color: transparent !important; }

/* Text meant only for screen readers. */
.GN-screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

#GN-overlay {
  -webkit-transition: opacity 1s ease 0s;
  -moz-transition: opacity 1s ease 0s;
  -o-transition: opacity 1s ease 0s;
  transition: opacity 1s ease 0s;
  background-color: rgba(130, 140, 150, 0.8);
  height: 100% !important;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 60px;
  width: 100% !important;
  z-index: -30; }

#GN-sub-nav {
  background-color: #57068c;
  font: 400 normal 1em "Gotham SSm 4r", "Gotham SSm A", "Gotham SSm B", Arial, sans-serif;
  max-width: 100%;
  position: relative;
  width: inherit;
  z-index: 9999; }
  #GN-sub-nav img {
    display: inline-block;
    vertical-align: middle;
    margin: auto; }
  #GN-sub-nav button {
    border: 0;
    background: none;
    cursor: pointer;
    padding: 0; }
  #GN-sub-nav #GN-banner {
    margin: 0 auto;
    position: relative;
    z-index: 30; }
    #GN-sub-nav #GN-banner #GN-icons-layer {
      -webkit-transition: left 1s;
      -moz-transition: left 1s;
      -ms-transition: left 1s;
      -o-transition: left 1s;
      transition: left 1s;
      background-color: #57068c;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      left: 0;
      position: relative;
      z-index: 30; }
      #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-search-box, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-global-nav, #GN-sub-nav #GN-banner #GN-icons-layer #GN-covid-btn {
        -webkit-transition: background-color 0.3s ease 0s;
        -moz-transition: background-color 0.3s ease 0s;
        -o-transition: background-color 0.3s ease 0s;
        transition: background-color 0.3s ease 0s;
        color: white;
        height: 60px;
        position: relative;
        line-height: 60px;
        padding-left: 15px;
        padding-right: 15px; }
        #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo svg, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav svg, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-search-box svg, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-global-nav svg, #GN-sub-nav #GN-banner #GN-icons-layer #GN-covid-btn svg {
          fill: white;
          vertical-align: middle; }
        #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo::after, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav::after, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-search-box::after, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-global-nav::after, #GN-sub-nav #GN-banner #GN-icons-layer #GN-covid-btn::after {
          -webkit-transition: opacity 0.3s ease 0s;
          -moz-transition: opacity 0.3s ease 0s;
          -o-transition: opacity 0.3s ease 0s;
          transition: opacity 0.3s ease 0s;
          background: white;
          bottom: 0;
          content: "";
          height: 4px;
          left: 0;
          opacity: 0;
          position: absolute;
          width: 100%; }
        #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo:hover, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav:hover, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-search-box:hover, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-global-nav:hover, #GN-sub-nav #GN-banner #GN-icons-layer #GN-covid-btn:hover {
          background-color: #330662; }
          #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo:hover::after, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav:hover::after, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-search-box:hover::after, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-global-nav:hover::after, #GN-sub-nav #GN-banner #GN-icons-layer #GN-covid-btn:hover::after {
            opacity: 1; }
      #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav {
        line-height: 50px;
        border: 0;
        background: none; }
        #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav.permanent {
          display: block !important; }
        #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav.active .GN-nav-bars {
          top: 9px;
          transition-delay: .22s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transform: translate3d(0, -10px, 0) rotate(-45deg); }
          #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav.active .GN-nav-bars::before {
            top: 0;
            transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
            transform: rotate(-90deg); }
          #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav.active .GN-nav-bars::after {
            top: 0;
            transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.22s;
            opacity: 0; }
        #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav .GN-nav-bars {
          background: white;
          height: 4px;
          line-height: 60px;
          position: relative;
          width: 22px; }
          #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav .GN-nav-bars::before, #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav .GN-nav-bars::after {
            transition: all .2s;
            position: absolute;
            content: "";
            background: white;
            height: 4px;
            width: 22px;
            left: 0;
            top: -9px; }
          #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav .GN-nav-bars::after {
            top: 9px; }
      #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo {
        padding: 0 !important;
        margin-left: 0;
        margin-right: auto;
        line-height: 59px;
        display: flex;
        align-items: center; }
        #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo .GN-logo-full {
          display: none; }
      #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right {
        margin-left: auto; }
        #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > div, #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > button, #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > a {
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
          margin-left: 1px;
          margin-right: 0;
          position: relative; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > div::before, #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > button::before, #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > a::before {
            background: #fff;
            content: "";
            height: 30px;
            position: absolute;
            right: -1px;
            top: 15px;
            width: 1px; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > div:last-child, #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > button:last-child, #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > a:last-child {
            margin-right: 0; }
            #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > div:last-child::before, #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > button:last-child::before, #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right > a:last-child::before {
              content: none; }
        #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-search {
          padding-left: 15px;
          padding-right: 15px;
          display: none; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-search::before {
            top: 4px; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-search #GN-search-text-field-container-desktop {
            position: relative; }
            #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-search #GN-search-text-field-container-desktop .GN-search-btn {
              top: 11px; }
        #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn {
          font: 400 normal 1em "Gotham SSm 4r", "Gotham SSm A", "Gotham SSm B", Arial, sans-serif;
          font-size: .75em;
          text-decoration: none;
          line-height: 60px;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          align-items: center;
          vertical-align: top; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn .GN-btn-txt.GN-btn-txt-long {
            display: none; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn .GN-btn-txt.GN-btn-txt-short {
            display: inline-block; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn svg {
            width: 16px;
            height: 16px;
            padding: 0 0 3px 0; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn #GN-covid-info-text-big {
            display: none; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn #GN-covid-info-text-small {
            display: inline; }
        #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-toggle-global-nav {
          font: 400 normal 1em "Gotham SSm 4r", "Gotham SSm A", "Gotham SSm B", Arial, sans-serif;
          font-size: .75em;
          line-height: 50px; }
          #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-toggle-global-nav .GN-btn-txt {
            display: none; }
    #GN-sub-nav #GN-banner input {
      -webkit-transition: background-color 350ms ease 0s;
      -moz-transition: background-color 350ms ease 0s;
      -o-transition: background-color 350ms ease 0s;
      transition: background-color 350ms ease 0s;
      background-color: #57068c;
      border: 0;
      border-bottom: 1px solid white;
      border-radius: 0;
      color: #b8b8b8;
      font: 400 normal 1em "Gotham SSm 4r", "Gotham SSm A", "Gotham SSm B", Arial, sans-serif;
      font-size: 0.95em;
      letter-spacing: 1px;
      line-height: 24px;
      margin: 0;
      padding: 6px 25px 0 5px;
      width: 228px;
      text-indent: 8px;
      /* FF 4-18 */
      /* FF 19+ */
      /* IE 10+ */
      /*
			&:focus::-webkit-input-placeholder {
				color: transparent;
			}

			&:focus:-moz-placeholder {
				color: transparent;
			}

			// FF 4-18 //
			&:focus::-moz-placeholder {
				color: transparent;
			}

			// FF 19+ //
			&:focus:-ms-input-placeholder {
				color: transparent;
			}
			*/ }
      #GN-sub-nav #GN-banner input::-webkit-input-placeholder {
        color: #9885ae;
        font-size: 0.688em;
        left: -5px;
        opacity: 1;
        position: relative; }
      #GN-sub-nav #GN-banner input:-moz-placeholder {
        color: #9885ae;
        font-size: 0.688em;
        left: -5px;
        opacity: 1;
        position: relative; }
      #GN-sub-nav #GN-banner input::-moz-placeholder {
        color: #9885ae;
        font-size: 0.688em;
        left: -5px;
        opacity: 1;
        position: relative; }
      #GN-sub-nav #GN-banner input:-ms-input-placeholder {
        color: #9885ae;
        font-size: 0.688em;
        left: -5px;
        opacity: 1;
        position: relative; }
      #GN-sub-nav #GN-banner input :-ms-input-placeholder {
        /* IE10–11 */
        color: #9885ae !important;
        font-size: 0.688em !important;
        left: -5px !important;
        opacity: 1 !important;
        position: relative !important; }
      #GN-sub-nav #GN-banner input ::-ms-input-placeholder {
        /* Edge */
        color: #9885ae;
        font-size: 0.688em;
        left: -5px;
        opacity: 1;
        position: relative; }
      #GN-sub-nav #GN-banner input:active {
        background-color: white; }
      #GN-sub-nav #GN-banner input:focus {
        background-color: white;
        color: #000;
        outline: none; }
        #GN-sub-nav #GN-banner input:focus + .GN-search-btn {
          opacity: .5; }
          #GN-sub-nav #GN-banner input:focus + .GN-search-btn svg {
            fill: #57068c; }
          #GN-sub-nav #GN-banner input:focus + .GN-search-btn:hover {
            opacity: 1; }
    #GN-sub-nav #GN-banner .GN-search-btn {
      -webkit-transition: opacity 0.3s ease 0s;
      -moz-transition: opacity 0.3s ease 0s;
      -o-transition: opacity 0.3s ease 0s;
      transition: opacity 0.3s ease 0s;
      background: none;
      border: 0;
      bottom: 4px;
      cursor: pointer;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 4px;
      top: 22px;
      width: 17px;
      overflow: hidden; }
      #GN-sub-nav #GN-banner .GN-search-btn svg {
        fill: white; }
    #GN-sub-nav #GN-banner #GN-search-form {
      background-color: #330662;
      height: 60px;
      left: 0;
      line-height: 58px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      display: none; }
      #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container {
        padding: 17px 15px;
        position: relative; }
        #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container input {
          background-color: white;
          box-sizing: border-box;
          color: #000;
          font: 400 normal 1em "Gotham 4r", "Gotham A", "Gotham B", Arial, sans-serif;
          outline: none;
          width: 100%;
          /* FF 4-18 */
          /* FF 19+ */
          /* IE 10+ */ }
          #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container input::-webkit-input-placeholder {
            left: 0;
            top: -3px; }
          #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container input:-moz-placeholder {
            left: 0;
            top: -3px; }
          #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container input::-moz-placeholder {
            left: 0;
            top: -3px; }
          #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container input:-ms-input-placeholder {
            left: 0;
            top: -3px; }
        #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container .GN-search-btn {
          -webkit-transition: opacity 0.5s ease 0s;
          -moz-transition: opacity 0.5s ease 0s;
          -o-transition: opacity 0.5s ease 0s;
          transition: opacity 0.5s ease 0s;
          right: 20px;
          opacity: .25; }
          #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container .GN-search-btn svg {
            fill: #57068c; }
          #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container .GN-search-btn:hover {
            opacity: 1; }
  #GN-sub-nav #GN-global-nav {
    -webkit-transition: right 1s ease 0s;
    -moz-transition: right 1s ease 0s;
    -o-transition: right 1s ease 0s;
    transition: right 1s ease 0s;
    background-color: #330662;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    right: -264px;
    top: 0;
    width: 264px;
    z-index: 900;
    height: 100% !important;
    -webkit-overflow-scrolling: scroll; }
    #GN-sub-nav #GN-global-nav-body {
      display: none; }
    #GN-sub-nav #GN-global-nav #GN-global-nav-nyu-logo {
      line-height: 60px;
      height: 60px;
      padding: 0 15px; }
      #GN-sub-nav #GN-global-nav #GN-global-nav-nyu-logo svg {
        fill: white;
        vertical-align: middle;
        width: 85px; }
      #GN-sub-nav #GN-global-nav #GN-global-nav-nyu-logo a {
        line-height: 60px;
        display: block;
        height: 60px;
        width: 85px;
        padding: 0 15px; }
    #GN-sub-nav #GN-global-nav .GN-nyu-login-btn {
      margin: 30px 15px 40px; }
      #GN-sub-nav #GN-global-nav .GN-nyu-login-btn a {
        -webkit-transition: background-color 0.2s, color 0.2s;
        -moz-transition: background-color 0.2s, color 0.2s;
        -ms-transition: background-color 0.2s, color 0.2s;
        -o-transition: background-color 0.2s, color 0.2s;
        transition: background-color 0.2s, color 0.2s;
        background: #330662;
        border: 1px solid white;
        color: white;
        display: block;
        font-size: 0.875em;
        height: 35px;
        line-height: 35px;
        min-width: 180px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase; }
        #GN-sub-nav #GN-global-nav .GN-nyu-login-btn a:hover {
          background: white;
          color: #57068c; }
    #GN-sub-nav #GN-global-nav .GN-close-btn {
      -webkit-transition: opacity 0.5s ease 0s;
      -moz-transition: opacity 0.5s ease 0s;
      -o-transition: opacity 0.5s ease 0s;
      transition: opacity 0.5s ease 0s;
      cursor: pointer;
      height: 18px;
      opacity: .5;
      position: absolute;
      right: 12px;
      text-indent: 100%;
      top: 12px;
      width: 18px;
      z-index: 998; }
      #GN-sub-nav #GN-global-nav .GN-close-btn::before, #GN-sub-nav #GN-global-nav .GN-close-btn::after {
        background: white;
        content: "";
        height: 18px;
        left: 8px;
        position: absolute;
        top: 0;
        width: 2px; }
      #GN-sub-nav #GN-global-nav .GN-close-btn::before {
        transform: rotate(45deg); }
      #GN-sub-nav #GN-global-nav .GN-close-btn::after {
        transform: rotate(-45deg); }
      #GN-sub-nav #GN-global-nav .GN-close-btn:hover {
        opacity: 1; }
    #GN-sub-nav #GN-global-nav #GN-accordion > div {
      margin-top: 30px; }
    #GN-sub-nav #GN-global-nav #GN-accordion .GN-reveal-sub-nav .GN-accordion-title {
      color: white;
      font: 400 normal 1em "Gotham 4r", "Gotham A", "Gotham B", Arial, sans-serif;
      font-size: 1.5em; }
      #GN-sub-nav #GN-global-nav #GN-accordion .GN-reveal-sub-nav .GN-accordion-title:before {
        -webkit-transform: translateZ(0) scale(1, 1) rotate(0);
        transform: rotate(0); }
    #GN-sub-nav #GN-global-nav #GN-accordion .GN-reveal-sub-nav .GN-accordion-body {
      opacity: 1;
      max-height: 2000px !important; }
    #GN-sub-nav #GN-global-nav #GN-accordion .GN-accordion-title {
      color: #9885ae;
      cursor: pointer;
      font: 300 normal 1em "Gotham 3r", "Gotham A", "Gotham B", Arial, sans-serif;
      font-size: 1.5em;
      padding: 0 0 0 40px;
      position: relative; }
      #GN-sub-nav #GN-global-nav #GN-accordion .GN-accordion-title:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4xIDE0LjUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNmZmY7fTwvc3R5bGU+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNyAwIDEwLjUgNi41IDQgMCAwIDQgNi41IDEwLjUgMTAuNSAxNC41IDE0LjUgMTAuNSAyMS4xIDQgIi8+PC9zdmc+");
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        height: 0.495em;
        position: absolute;
        transform: rotate(-90deg);
        width: 0.75em;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1, 1) rotate(-90deg);
        -webkit-transition: left 0.2s, transform 0.4s;
        -moz-transition: left 0.2s, transform 0.4s;
        -ms-transition: left 0.2s, transform 0.4s;
        -o-transition: left 0.2s, transform 0.4s;
        transition: left 0.2s, transform 0.4s;
        left: 15px;
        top: 20%; }
      #GN-sub-nav #GN-global-nav #GN-accordion .GN-accordion-title:hover {
        color: white; }
    #GN-sub-nav #GN-global-nav #GN-accordion .GN-accordion-body {
      -webkit-transition: opacity 0.6s, max-height 0.5s;
      -moz-transition: opacity 0.6s, max-height 0.5s;
      -ms-transition: opacity 0.6s, max-height 0.5s;
      -o-transition: opacity 0.6s, max-height 0.5s;
      transition: opacity 0.6s, max-height 0.5s;
      margin-top: 15px;
      max-height: 0;
      opacity: 0.1;
      overflow: hidden; }
      #GN-sub-nav #GN-global-nav #GN-accordion .GN-accordion-body a {
        color: #9885ae;
        display: block;
        font: 300 normal 1em "Gotham 3r", "Gotham A", "Gotham B", Arial, sans-serif;
        padding: 15px 30px 15px 40px;
        text-decoration: none; }
        #GN-sub-nav #GN-global-nav #GN-accordion .GN-accordion-body a:hover {
          color: white; }
    #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations {
      /*
				.GN-accordion{
					display: none;
				}
				*/ }
      #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations.GN-reveal-sub-nav .GN-accordion {
        display: block; }
        #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations.GN-reveal-sub-nav .GN-accordion-body {
          display: block; }
      #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body {
        display: none;
        font-size: 1.250em;
        text-transform: uppercase; }
        #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary li:nth-child(1) a {
          color: #a9b240; }
        #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary li:nth-child(2) a {
          color: #02b79d; }
        #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary li:nth-child(3) a {
          color: #dd7127; }
        #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary li a:hover {
          color: white; }
        #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-secondary {
          font-size: .85em; }
        #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body a:hover {
          background: #6d6d6d; }
    #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools.GN-reveal-sub-nav .GN-accordion-body {
      display: block; }
    #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body {
      display: none; }
      #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body a:hover {
        background: #6d6d6d; }
      #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body ul > li > ul a {
        padding-left: 70px; }
  #GN-sub-nav #GN-local-nav {
    -webkit-transition: left 1s ease 0s;
    -moz-transition: left 1s ease 0s;
    -o-transition: left 1s ease 0s;
    transition: left 1s ease 0s;
    background-color: white;
    left: -264px;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 264px;
    z-index: 900;
    -webkit-overflow-scrolling: scroll; }
    #GN-sub-nav #GN-local-nav-body {
      display: none; }

.GN-mquery-tablet #GN-sub-nav #GN-banner #GN-icons-layer {
  justify-content: space-between; }
  .GN-mquery-tablet #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo {
    margin-right: auto;
    margin-left: auto; }
    .GN-mquery-tablet #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo .GN-logo-full {
      display: inline-block; }
    .GN-mquery-tablet #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo .GN-logo-short {
      display: none; }
  .GN-mquery-tablet #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right {
    margin-left: unset; }

.GN-mquery-tablet #GN-sub-nav #GN-search-form #GN-search-text-field-container {
  float: right; }
  .GN-mquery-tablet #GN-sub-nav #GN-search-form #GN-search-text-field-container input {
    width: 235px; }

.GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer {
  justify-content: flex-start; }
  .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer #GN-toggle-local-nav {
    display: none; }
  .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo {
    margin-left: 15px;
    margin-right: auto; }
    .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo .GN-logo-full {
      display: inline-block; }
    .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer #GN-logo .GN-logo-short {
      display: none; }
  .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-toggle-search-box {
    display: none; }
  .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-search {
    display: inline-block; }
  .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-toggle-global-nav .GN-btn-txt {
    display: inline-block;
    padding-left: 3px; }
  .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn {
    display: inline-block;
    flex-direction: unset;
    justify-content: unset;
    align-content: unset;
    align-items: unset;
    vertical-align: unset; }
    .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn .GN-btn-txt.GN-btn-txt-long {
      display: inline-block; }
    .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn .GN-btn-txt.GN-btn-txt-short {
      display: none; }
    .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn svg {
      width: 20px;
      height: 20px;
      padding: 0 3px 0 0; }
    .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn #GN-covid-info-text-big {
      display: inline; }
    .GN-mquery-desktop #GN-sub-nav #GN-banner #GN-icons-layer .GN-icons-right #GN-covid-btn #GN-covid-info-text-small {
      display: none; }

.GN-mquery-desktop #GN-sub-nav #GN-banner #GN-search-form {
  display: none; }

.GN-mquery-desktop #GN-sub-nav #GN-global-nav {
  -webkit-transition: opacity 1s, transform 0.5s;
  -moz-transition: opacity 1s, transform 0.5s;
  -ms-transition: opacity 1s, transform 0.5s;
  -o-transition: opacity 1s, transform 0.5s;
  transition: opacity 1s, transform 0.5s;
  background-color: transparent;
  left: 50%;
  max-width: 930px;
  opacity: 0;
  position: fixed;
  transform: translateX(-50%) scale(3);
  visibility: hidden;
  width: 100%;
  z-index: 999;
  height: 100% !important; }
  .GN-mquery-desktop #GN-sub-nav #GN-global-nav .GN-nyu-login-btn {
    margin: 0;
    position: absolute;
    right: 135px;
    top: 30px;
    z-index: 1; }
  .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-global-nav-nyu-logo {
    display: none; }
  .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations {
    background: #330662;
    margin-top: 0;
    padding: 16px 20px; }
    .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-title {
      display: none; }
    .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body {
      margin-top: 0;
      max-height: unset;
      opacity: 1;
      display: block; }
      .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary {
        display: inline-block !important;
        width: 32.6%; }
        .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary li {
          line-height: 1.5; }
          .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary li a {
            -webkit-transition: padding-left 0.2s ease 0s;
            -moz-transition: padding-left 0.2s ease 0s;
            -o-transition: padding-left 0.2s ease 0s;
            transition: padding-left 0.2s ease 0s;
            display: inline-block;
            font-size: 2.2em;
            font-weight: 100;
            padding: 0;
            position: relative; }
            .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary li a::before {
              background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4xIDE0LjUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNmZmY7fTwvc3R5bGU+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNyAwIDEwLjUgNi41IDQgMCAwIDQgNi41IDEwLjUgMTAuNSAxNC41IDE0LjUgMTAuNSAyMS4xIDQgIi8+PC9zdmc+");
              background-repeat: no-repeat;
              background-size: contain;
              content: "";
              height: 11.22px;
              position: absolute;
              transform: rotate(-90deg);
              width: 17px;
              -webkit-backface-visibility: hidden;
              -webkit-transform: translateZ(0) scale(1, 1) rotate(-90deg);
              -webkit-transition: left 0.2s, opacity 0.2s;
              -moz-transition: left 0.2s, opacity 0.2s;
              -ms-transition: left 0.2s, opacity 0.2s;
              -o-transition: left 0.2s, opacity 0.2s;
              transition: left 0.2s, opacity 0.2s;
              left: -25px;
              opacity: 0;
              top: 35%; }
            .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary li a:hover {
              background: none;
              padding-left: 25px; }
              .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-primary li a:hover::before {
                left: 5px;
                opacity: 1; }
      .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-secondary {
        display: inline-block !important;
        width: 64%; }
        .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-secondary li {
          display: inline-block; }
          .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-secondary li a {
            color: #1ba7d4;
            font: 400 normal 1em "Gotham SSm 4r", "Gotham SSm A", "Gotham SSm B", Arial, sans-serif;
            font-size: 0.75em;
            padding: 4px 12px 3px 12px;
            position: relative; }
            .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-secondary li a::before {
              background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4xIDE0LjUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNmZmY7fTwvc3R5bGU+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNyAwIDEwLjUgNi41IDQgMCAwIDQgNi41IDEwLjUgMTAuNSAxNC41IDE0LjUgMTAuNSAyMS4xIDQgIi8+PC9zdmc+");
              background-repeat: no-repeat;
              background-size: contain;
              content: "";
              height: 0.528em;
              position: absolute;
              transform: rotate(-90deg);
              width: 0.8em;
              -webkit-backface-visibility: hidden;
              -webkit-transform: translateZ(0) scale(1, 1) rotate(-90deg);
              -webkit-transition: left 0.3s ease 0s;
              -moz-transition: left 0.3s ease 0s;
              -o-transition: left 0.3s ease 0s;
              transition: left 0.3s ease 0s;
              left: 0;
              top: .6em;
              opacity: .5; }
            .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-secondary li a:hover {
              background: none;
              color: white; }
              .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-secondary li a:hover::before {
                left: 4px;
                opacity: 1; }
          .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-secondary li:nth-child(even) a {
            color: #86c3e8; }
            .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-locations .GN-accordion-body .GN-locations-secondary li:nth-child(even) a:hover {
              color: white; }
  .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools {
    display: none;
    background: #57068c;
    margin-top: 4px;
    padding: 25px 0; }
    .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-title {
      box-sizing: border-box;
      color: white;
      cursor: default;
      display: inline-block;
      padding: 0 0 0 25px;
      vertical-align: top;
      width: 34%;
      text-align: left; }
      .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-title::before {
        content: none; }
    .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body {
      column-count: 2;
      display: inline-block !important;
      height: 342px;
      min-height: 352px;
      margin-top: 0;
      max-height: unset;
      opacity: 1;
      width: 66%;
      -webkit-column-fill: auto;
      -moz-column-fill: auto;
      column-fill: auto; }
      .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body li {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid; }
        .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body li a {
          -webkit-transition: padding-left 0.2s ease 0s;
          -moz-transition: padding-left 0.2s ease 0s;
          -o-transition: padding-left 0.2s ease 0s;
          transition: padding-left 0.2s ease 0s;
          color: white;
          font-size: .85em;
          font-weight: 300;
          line-height: 1.25;
          padding: 8px 0;
          position: relative; }
          .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body li a::before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4xIDE0LjUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNmZmY7fTwvc3R5bGU+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNyAwIDEwLjUgNi41IDQgMCAwIDQgNi41IDEwLjUgMTAuNSAxNC41IDE0LjUgMTAuNSAyMS4xIDQgIi8+PC9zdmc+");
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            height: 0.495em;
            position: absolute;
            transform: rotate(-90deg);
            width: 0.75em;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0) scale(1, 1) rotate(-90deg);
            -webkit-transition: left 0.2s, opacity 0.2s;
            -moz-transition: left 0.2s, opacity 0.2s;
            -ms-transition: left 0.2s, opacity 0.2s;
            -o-transition: left 0.2s, opacity 0.2s;
            transition: left 0.2s, opacity 0.2s;
            left: -20px;
            opacity: 0;
            top: .9em; }
          .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body li a:hover {
            background: none;
            color: #CA8CE9;
            padding-left: 20px; }
            .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body li a:hover::before {
              left: 5px;
              opacity: 1; }
        .GN-mquery-desktop #GN-sub-nav #GN-global-nav #GN-accordion #GN-accordion-schools .GN-accordion-body li ul {
          margin-left: 30px; }

.GN-search-form-active {
  overflow: hidden; }
  .GN-search-form-active #GN-overlay {
    opacity: 1;
    pointer-events: auto; }
  .GN-search-form-active #GN-sub-nav #GN-banner #GN-toggle-search-box {
    background-color: #330662;
    box-sizing: border-box; }
  .GN-search-form-active #GN-sub-nav #GN-banner #GN-search-form {
    display: block;
    top: 60px; }
    .GN-search-form-active #GN-sub-nav #GN-banner #GN-search-form #GN-search-text-field-container {
      display: block; }

.GN-reveal-global {
  overflow: hidden; }
  .GN-reveal-global #GN-overlay {
    opacity: 1;
    pointer-events: auto; }
  .GN-reveal-global #GN-global-nav-body {
    display: block !important; }
  .GN-reveal-global #GN-accordion-body {
    display: block !important; }
  .GN-reveal-global #GN-accordion-schools {
    display: block !important; }
  .GN-reveal-global #GN-sub-nav #GN-banner #GN-toggle-global-nav {
    background-color: #330662; }
    .GN-reveal-global #GN-sub-nav #GN-banner #GN-toggle-global-nav:hover {
      border-bottom-color: white; }
  .GN-reveal-global.GN-mquery-phone #GN-sub-nav #GN-banner #GN-icons-layer, .GN-reveal-global.GN-mquery-tablet #GN-sub-nav #GN-banner #GN-icons-layer {
    left: -264px; }
  .GN-reveal-global.GN-mquery-phone #GN-sub-nav #GN-global-nav, .GN-reveal-global.GN-mquery-tablet #GN-sub-nav #GN-global-nav {
    display: block;
    right: 0; }
  .GN-reveal-global.GN-mquery-desktop #GN-sub-nav #GN-global-nav {
    opacity: 1;
    transform: translateX(-50%) scale(1);
    visibility: visible; }
  .GN-reveal-global.GN-mquery-desktop #GN-sub-nav #GN-global-nav-body {
    top: 50%;
    transform: translateY(-50%);
    position: relative; }

.GN-reveal-local {
  overflow: hidden; }
  .GN-reveal-local #GN-overlay {
    opacity: 1;
    pointer-events: auto; }
  .GN-reveal-local #GN-sub-nav #GN-banner #GN-toggle-local-nav {
    background-color: #330662; }
    .GN-reveal-local #GN-sub-nav #GN-banner #GN-toggle-local-nav:hover {
      border-bottom-color: white; }
  .GN-reveal-local.GN-mquery-phone #GN-sub-nav #GN-banner #GN-icons-layer, .GN-reveal-local.GN-mquery-tablet #GN-sub-nav #GN-banner #GN-icons-layer {
    left: 264px; }
  .GN-reveal-local.GN-mquery-phone #GN-sub-nav #GN-local-nav, .GN-reveal-local.GN-mquery-tablet #GN-sub-nav #GN-local-nav {
    display: block;
    left: 0; }
    .GN-reveal-local.GN-mquery-phone #GN-sub-nav #GN-local-nav-body, .GN-reveal-local.GN-mquery-tablet #GN-sub-nav #GN-local-nav-body {
      display: block; }
