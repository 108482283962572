@mixin transition( $property: all, $duration: 1s, $timing_function: ease, $delay: 0s ) {
	-webkit-transition: $property $duration $timing_function $delay;
	   -moz-transition: $property $duration $timing_function $delay;
	     -o-transition: $property $duration $timing_function $delay;
	        transition: $property $duration $timing_function $delay;
}

@mixin transitions($args...) {
	-webkit-transition: $args;
	   -moz-transition: $args;
	    -ms-transition: $args;
	     -o-transition: $args;
	        transition: $args;
}

@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content} /* FF 4-18 */
	&::-moz-placeholder          {@content} /* FF 19+ */
	&:-ms-input-placeholder      {@content} /* IE 10+ */
}

@mixin cheveron-white($width, $rotation){

	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4xIDE0LjUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNmZmY7fTwvc3R5bGU+PHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxNyAwIDEwLjUgNi41IDQgMCAwIDQgNi41IDEwLjUgMTAuNSAxNC41IDE0LjUgMTAuNSAyMS4xIDQgIi8+PC9zdmc+");
	background-repeat: no-repeat;
	background-size: contain;
	content: "";
	height: $width * 0.66;
	position: absolute;
	transform: rotate($rotation);
	width: $width ;
	
	//fix for vibration on animation bug
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(1.0, 1.0) rotate($rotation);
}
